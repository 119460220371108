import React from 'react';
import { useIntl } from 'react-intl';
import styles from './sendSweetheartz.module.scss';
import { Container, Row, Col, Image, Button } from '../../../components';

const SendSweetheartz = () => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <Container>
        <Row span={12} className={styles.row}>
          <Col span={4} startCol={2} spanMd={12} startColMd={1}>
            <div className={styles.images}>
              <div className={styles.bigHeart}>
                <Image placeholderLg objectFit="contain" loadFromStorage src="/index/h_big.png" />
              </div>
              <div className={styles.heartsList}>
                <div className={styles.heart}>
                  <Image objectFit="contain" loadFromStorage src="/common/hearts/h_4.png" />
                </div>
                <div className={styles.heart}>
                  <Image objectFit="contain" loadFromStorage src="/common/hearts/h_0.png" />
                </div>
                <div className={styles.heart}>
                  <Image objectFit="contain" loadFromStorage src="/common/hearts/h_1.png" />
                </div>
                <div className={styles.heart}>
                  <Image objectFit="contain" loadFromStorage src="/common/hearts/h_2.png" />
                </div>
                <div className={styles.heart}>
                  <Image objectFit="contain" loadFromStorage src="/common/hearts/h_3.png" />
                </div>
              </div>
            </div>
          </Col>
          <Col span={5} startCol={7}>
            <div className={styles.title}>{intl.formatMessage({ id: 'index.sendSweetheartz.title' })}</div>
            <div className={styles.description}>{intl.formatMessage({ id: 'index.sendSweetheartz.description' })}</div>
            <Button
              className={styles.cta}
              md
              secondary
              href={process.env.NEXT_PUBLIC_OPENSEA_COLLECTION}
              target="_blank"
            >
              {intl.formatMessage({ id: 'index.sendSweetheartz.openSea' })}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SendSweetheartz;

SendSweetheartz.propTypes = {};

SendSweetheartz.defaultProps = {};
