const getMessage = (intl, message, id, options) => {
  return message || (intl && id && intl.formatMessage({ id }, { ...options })) || undefined;
};

export const required = (intl, message) => ({
  key: 'required',
  test: value => {
    message = getMessage(intl, message, 'common.validation.required');
    if (!!(typeof value !== 'undefined') && value != null) {
      if (Array.isArray(value)) {
        return {
          isValid: value.length > 0,
          message,
        };
      }

      return {
        isValid: !!value.toString().trim().length,
        message,
      };
    }

    return { isValid: false, message };
  },
});

const _isObjectEmpty = value => Object.keys(value).length === 0 && value.constructor === Object;
export const notEmptyObject = (intl, message) => ({
  key: 'notEmptyObject',
  test: value => {
    message = getMessage(intl, message, 'common.validation.required');
    if (!!(typeof value !== 'undefined') && value != null) {
      if (Array.isArray(value)) {
        if (value.some(s => _isObjectEmpty(s))) {
          return {
            isValid: false,
            message,
          };
        }
      } else {
        return {
          isValid: !_isObjectEmpty(value),
          message,
        };
      }

      return {
        isValid: true,
        message,
      };
    }

    return { isValid: false, message };
  },
});

export const email = (intl, message) => ({
  key: 'email',
  test: email => {
    if (email === '') {
      return { isValid: true, message };
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/;
    const onlyLatinLetters = !/[^\u0000-\u007f]/.test(email); // eslint-disable-line
    return {
      isValid: emailRegex.test(email) && onlyLatinLetters,
      message: getMessage(intl, message, 'common.validation.email'),
    };
  },
});

export const decimal = (intl, message) => ({
  key: 'decimal',
  test: value => {
    const decimalRegex = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
    return {
      isValid: decimalRegex.test(value),
      message: getMessage(intl, message, 'common.validation.decimal'),
    };
  },
});

const _min = (value, minValue) => {
  if (value === '' || value === null || typeof value === 'undefined') {
    return true;
  }
  return value > minValue;
};
export const min = (minValue, intl, message) => ({
  key: 'min',
  test: value => {
    return {
      isValid: _min(value, minValue),
      message: getMessage(intl, message, 'common.validation.min', { value: minValue }),
    };
  },
});

const _minOrEqual = (value, minValue) => {
  if (value === '' || value === null || typeof value === 'undefined') {
    return true;
  }

  return value >= minValue;
};
export const minOrEqual = (minValue, intl, message) => ({
  key: 'minOrEqual',
  test: value => {
    return {
      isValid: _minOrEqual(value, minValue),
      message: getMessage(intl, message, 'common.validation.minEqual', { value: minValue }),
    };
  },
});

const _max = (value, maxValue) => {
  if (value === '' || value === null || typeof value === 'undefined') {
    return true;
  }
  return value < maxValue;
};
export const max = (maxValue, intl, message) => ({
  key: 'max',
  test: value => {
    return {
      isValid: _max(value, maxValue),
      message: getMessage(intl, message, 'common.validation.max', { value: maxValue }),
    };
  },
});

const _maxOrEqual = (value, maxValue) => {
  if (value === '' || value === null || typeof value === 'undefined') {
    return true;
  }
  return value <= maxValue;
};
export const maxOrEqual = (maxValue, intl, message) => ({
  key: 'maxOrEqual',
  test: value => {
    return {
      isValid: _maxOrEqual(value, maxValue),
      message: getMessage(intl, message, 'common.validation.maxEqual', { value: maxValue }),
    };
  },
});

const _limitCharsTo = (value, limit) => {
  if (!value) {
    return true;
  }
  return value.length <= limit;
};
export const limitCharsTo = (limit, intl, message) => ({
  key: 'limitCharsTo',
  test: value => {
    return {
      isValid: _limitCharsTo(value, limit),
      message: getMessage(intl, message, 'common.validation.limitCharsTo', { value: limit }),
    };
  },
});

const _limitCharsFrom = (value, limit) => {
  if (!value) {
    return true;
  }

  return value.length >= limit;
};

export const limitCharsFrom = (limit, intl, message) => ({
  key: 'limitCharsFrom',
  test: value => {
    return {
      isValid: _limitCharsFrom(value, limit),
      message: getMessage(intl, message, 'common.validation.limitCharsFrom', { value: limit }),
    };
  },
});

const _limitCharsFromTo = (value, from, to) => value && value.length >= from && value.length <= to;
export const limitCharsFromTo = (from, to, intl, message) => ({
  key: 'limitCharsFromTo',
  test: value => {
    return {
      isValid: _limitCharsFromTo(value, from, to),
      message: getMessage(intl, message, 'common.validation.limitCharsFromTo', { from, to }),
    };
  },
});

const _limitWordsFrom = (value, from) => {
  if (!value) {
    return false;
  }

  const words = value
    .trim()
    .split(' ')
    .filter(c => !!c);
  return words.length >= from;
};
export const limitWordsFrom = (from, intl, message) => ({
  key: 'limitWordsFrom',
  test: value => {
    return {
      isValid: _limitWordsFrom(value, from),
      message: getMessage(intl, message, 'common.validation.limitWordsFrom', { value: from }),
    };
  },
});

export const password = (intl, message) => ({
  key: 'password',
  test: value => {
    if (!value) {
      return { isValid: true, message };
    }

    return {
      isValid: _limitCharsFrom(value, 6) && _limitCharsTo(value, 20),
      message: getMessage(intl, message, 'common.validation.password'),
    };
  },
});

export const passwordMatch = (intl, message) => ({
  key: 'passwordMatch',
  test: (value, matchValue) => {
    if (!value || !matchValue) return { isValid: true, message };
    return {
      isValid: value === matchValue,
      message: getMessage(intl, message, 'common.validation.passwordMatch'),
    };
  },
});

export const link = (intl, message) => ({
  key: 'link',
  test: value => {
    if (!value) {
      return { isValid: true, message };
    }
    return {
      isValid: value.startsWith('https://'),
      message: getMessage(intl, message, 'common.validation.link'),
    };
  },
});

// export const phone = (intl, message) => ({
//   key: 'passwordMatch',
//   test: phone => {
//     message = getRuleMessage(intl, message, 'common.validation.email');

//     if (phone === '') {
//       return { isValid: true, message };
//     }

//     const phoneObject = parsePhoneNumberFromString(phone, locale);
//     return {
//         isValid: phoneObject && phoneObject.isValid(),
//         message,
//     }
//   }
// });
