import React from 'react';
import IndexContainer from '../app/containers/index/index';
import { addSSGState } from '../app/gql/apollo/apollo';

const Index = () => {
  return <IndexContainer />;
};

export const getStaticProps = async () => {
  return addSSGState();
};

Index.pageName = 'Home';

export default Index;
