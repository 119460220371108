import Web3 from 'web3';
import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { Button, Tabs, Text } from '../../../components';
import styles from './mint.module.scss';
import { useResolution, useValidation, useWeb3, useAnalytics } from '../../../hooks';
import { required } from '../../../utils/validationRules';
import { ConfigContext } from '../../../contexts/ConfigContext';

const tabs = {
  other: 'other',
  owner: 'owner',
};

const Mint = () => {
  const { routeResolver } = useContext(ConfigContext);
  const router = useRouter();
  const { showWeb3Modal, validateAddress, connected, wrongNetwork, mint, authUserAddress, disconnect } = useWeb3();
  const { register, validate, remove, submit } = useValidation();
  const { track, events } = useAnalytics();
  const intl = useIntl();
  const { isSm } = useResolution();
  const [tabValue, setTabValue] = useState(tabs.other);
  const [address, setAddress] = useState('');
  const [mintLoading, setMintLoading] = useState(false);
  const [addressValidationObject, setAddressValidationObject] = useState(true);
  const [validatingEns, setValidatingEns] = useState(false);
  const validationOptions = { disabled: tabValue === tabs.owner };

  const onFormSubmit = async ({ isValid }) => {
    if (!Web3.utils.isAddress(address) && tabValue === tabs.other) {
      setValidatingEns(true);
      const isEns = await validateAddress(address);
      setValidatingEns(false);
      if (!isEns) {
        setAddressValidationObject({
          // eslint-disable-next-line
          helperText: intl.formatMessage({ id: 'common.validation.cryptoAddress' }),
          // eslint-disable-next-line
          status: 'error',
        });
        return;
      }
    }

    if (mintLoading) {
      return;
    }

    if (isValid) {
      setMintLoading(true);
      try {
        track(events.nftMintIntent, {
          type: tabValue,
        });
        await mint(tabValue === tabs.owner ? authUserAddress : address, trxHash => {
          track(events.nftMintSubmitted, {
            type: tabValue,
          });
          router.push(routeResolver.buildRouteFor(routeResolver.routes.details, { id: trxHash }));
        });
      } catch (err) {
        track(events.nftMintCanceled, {
          type: tabValue,
        });
        console.log('err', err);
      }
      setMintLoading(false);
    }
  };

  const renderDiscountPrice = children => {
    return (
      <div>
        <div className={styles.priceContent}>
          {intl.formatMessage(
            {
              id: 'index.mint.price.free',
            },
            {
              elem: value => <span className={styles.priceHighlighted}>{value}</span>,
            }
          )}
        </div>
        {children}
      </div>
    );
  };

  const getPrice = () => {
    return renderDiscountPrice();
  };

  const formatAddress = value => [value.slice(0, 6), value.slice(value.length - 4)].join('....');

  return (
    <form {...submit(onFormSubmit)} className={styles.root}>
      <Tabs
        source={[
          {
            text: intl.formatMessage({ id: !isSm ? 'index.mint.sendToValentine' : 'index.mint.sendToValentine.short' }),
            value: tabs.other,
          },
          {
            text: intl.formatMessage({ id: !isSm ? 'index.mint.mintYourWallet' : 'index.mint.mintYourWallet.short' }),
            value: tabs.owner,
          },
        ]}
        value={tabValue}
        onChange={item => {
          setTabValue(item.value);
          remove('address', { startsWith: true });
        }}
      />
      {tabValue === tabs.other && (
        <div>
          {!isSm && <div className={styles.textLabel}>{intl.formatMessage({ id: 'index.mint.text.label' })}</div>}
          <Text
            placeholder={intl.formatMessage({ id: 'index.mint.placeholder' })}
            value={address}
            onChange={e => setAddress(e.target.value)}
            onBlur={() => {
              setAddressValidationObject(null);
              validate('address', address);
            }}
            {...register(
              'address',
              address,
              [required(intl, intl.formatMessage({ id: 'common.validation.addressIsRequired' }))],
              validationOptions
            )}
            {...addressValidationObject}
          />
        </div>
      )}
      <div className={styles.price}>{getPrice()}</div>
      {connected ? (
        <div className={styles.ctaContainer}>
          <Button
            disabled={wrongNetwork}
            lg
            className={styles.cta}
            type="submit"
            loading={mintLoading || validatingEns}
          >
            {intl.formatMessage({ id: mintLoading ? 'index.mint.mintInProgress' : 'index.mint.cta' })}
          </Button>
          {authUserAddress && (
            <div className={styles.connectedBar}>
              <div className={styles.address}>
                {intl.formatMessage(
                  { id: 'index.mint.userAddress' },
                  {
                    address: formatAddress(authUserAddress),
                    elem: value => <span className={styles.addressValue}>{value}</span>,
                  }
                )}
              </div>
              <div className={styles.separator} />
              <Button noPaddings className={styles.disconnect} onClick={disconnect}>
                {intl.formatMessage({ id: 'index.mint.disconnect' })}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Button
          lg
          className={styles.cta}
          onClick={async () => {
            track(events.showWeb3WalletAuthModal);
            await showWeb3Modal();
          }}
        >
          {intl.formatMessage({ id: 'index.mint.connectToWallet' })}
        </Button>
      )}
      {wrongNetwork && connected && (
        <div className={styles.wrongNetwork}>
          {intl.formatMessage(
            { id: 'index.mint.wrongNetwork' },
            {
              network: process.env.NEXT_PUBLIC_NETWORK_NAME,
            }
          )}
        </div>
      )}
    </form>
  );
};

export default Mint;
