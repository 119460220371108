import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Container, Row, Col, Accordion } from '../../../components';
import styles from './FAQ.module.scss';
import { useUrl, useAnalytics } from '../../../hooks';
import { shareOnTwitter } from '../../../utils/shareUtils';

const FAQ = () => {
  const intl = useIntl();
  const [active, setActive] = useState();
  const { absoluteUrl } = useUrl();
  const { track, events } = useAnalytics();

  const renderLink = ([text, href, onClickHandler]) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
        onClick={onClickHandler || undefined}
      >
        {text}
      </a>
    );
  };

  const onTwitterShare = () => {
    shareOnTwitter(intl.formatMessage({ id: 'index.faq.answear9.tweet' }), absoluteUrl);
    track(events.shareOnTwitter, { url: absoluteUrl, type: 'index' });
  };

  // eslint-disable-next-line
  const renderTwitterLink = ([text]) => renderLink([text, undefined, onTwitterShare]);

  const source = [
    {
      title: intl.formatMessage({ id: 'index.faq.question1' }),
      text: intl.formatMessage({ id: 'index.faq.answear1' }),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question2' }),
      text: intl.formatMessage(
        { id: 'index.faq.answear2' },
        {
          link1: [
            intl.formatMessage({ id: 'index.faq.answear2.link1' }),
            'https://opensea.io/blog/guides/non-fungible-tokens/',
          ],
          link2: [intl.formatMessage({ id: 'index.faq.answear2.link2' }), 'https://twitter.com/blakeir'],
          link3: [intl.formatMessage({ id: 'index.faq.answear2.link3' }), 'https://youtu.be/yjLRq24siyY'],
          link: renderLink,
        }
      ),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question3' }),
      text: intl.formatMessage({ id: 'index.faq.answear3' }),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question4' }),
      text: intl.formatMessage(
        { id: 'index.faq.answear4' },
        {
          link1: [intl.formatMessage({ id: 'index.faq.answear4.link' }), 'https://nft.storage/'],
          link: renderLink,
        }
      ),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question5' }),
      text: intl.formatMessage({ id: 'index.faq.answear5' }),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question6' }),
      text: intl.formatMessage({ id: 'index.faq.answear6' }),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question7' }),
      text: intl.formatMessage({ id: 'index.faq.answear7' }),
    },
    {
      title: intl.formatMessage({ id: 'index.faq.question8' }),
      text: intl.formatMessage({ id: 'index.faq.answear8' }),
    },
    // {
    //   title: intl.formatMessage({ id: 'index.faq.question9' }),
    //   text: intl.formatMessage(
    //     { id: 'index.faq.answear9' },
    //     {
    //       link: renderTwitterLink,
    //     }
    //   ),
    // },
  ];

  return (
    <div className={styles.root}>
      <Container>
        <Row span={12}>
          <Col span={10} startCol={2} spanMd={12} startColMd={1}>
            <div className={styles.title}>{intl.formatMessage({ id: 'index.faq.title' })}</div>
            <Accordion
              source={source.map(m => ({
                ...m,
                expanded: m.title === active,
              }))}
              onClick={({ title }) => setActive(active === title ? undefined : title)}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQ;

FAQ.propTypes = {};

FAQ.defaultProps = {};
