export const shareTypes = {
  facebook: 'facebook',
  twitter: 'twitter',
  email: 'email',
  sms: 'sms',
};

export const shareOnTwitter = (message, url) => {
  const encodeUrl = encodeURIComponent(url);
  const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=${encodeUrl}`;
  const params = 'height=400, width=600, menubar=false, toolbar=false, left=600, top=200';
  // eslint-disable-next-line
  window.open(twitterUrl, null, params);
};
