import React from 'react';
import { useIntl } from 'react-intl';
import styles from './getSomethingUnique.module.scss';
import { Container, Row, Col } from '../../../components';
import { useLottieAnimation } from '../../../hooks';
import { buildImageStorageUrl } from '../../../utils/imageUtils';

const GetSomethingUnique = () => {
  const intl = useIntl();

  const animationItem1 = useLottieAnimation(buildImageStorageUrl('/index/lottie/1.json'));
  const animationItem2 = useLottieAnimation(buildImageStorageUrl('/index/lottie/2.json'));
  const animationItem3 = useLottieAnimation(buildImageStorageUrl('/index/lottie/3.json'));
  const animationItem4 = useLottieAnimation(buildImageStorageUrl('/index/lottie/4.json'));
  const animationItem5 = useLottieAnimation(buildImageStorageUrl('/index/lottie/5.json'));
  const animationItem6 = useLottieAnimation(buildImageStorageUrl('/index/lottie/6.json'));

  return (
    <div className={styles.root}>
      <Container>
        <Row span={12}>
          <Col span={10} startCol={2} spanMd={12} startColMd={1}>
            <div className={styles.title}>{intl.formatMessage({ id: 'index.getSomethingUnique.title' })}</div>
            <ul className={styles.list}>
              <li>
                <div className={styles.text}>{intl.formatMessage({ id: 'index.getSomethingUnique.item1' })}</div>
              </li>
              <li>
                <div className={styles.text}>{intl.formatMessage({ id: 'index.getSomethingUnique.item2' })}</div>
              </li>
              <li>
                <div className={styles.text}>{intl.formatMessage({ id: 'index.getSomethingUnique.item3' })}</div>
              </li>
              <li>
                <div className={styles.text}>{intl.formatMessage({ id: 'index.getSomethingUnique.item4' })}</div>
              </li>
            </ul>
          </Col>
        </Row>
        <Row span={12}>
          <Col span={10} startCol={2} spanMd={12} startColMd={1}>
            <div className={styles.heartsList}>
              <div className={styles.heart}>{animationItem1}</div>
              <div className={styles.heart}>{animationItem2}</div>
              <div className={styles.heart}>{animationItem3}</div>
              <div className={styles.heart}>{animationItem4}</div>
              <div className={styles.heart}>{animationItem5}</div>
              <div className={styles.heart}>{animationItem6}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GetSomethingUnique;

GetSomethingUnique.propTypes = {};

GetSomethingUnique.defaultProps = {};
