import React from 'react';
import { useIntl } from 'react-intl';
import Head from 'next/head';
import { Layout1, Container, Row, Col, Image, HeartAnimation } from '../../components';
import styles from './index.module.scss';
import { buildImageStorageUrl } from '../../utils/imageUtils';
import { useUrl } from '../../hooks';
import Mint from './components/mint';
import SendSweetheartz from './components/sendSweetheartz';
import GetSomethingUnique from './components/getSomethingUnique';
import FAQ from './components/FAQ';

const Index = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'page.index.title' });
  const pageDescription = intl.formatMessage({ id: 'page.index.description' });
  const pageImage = buildImageStorageUrl('/index/cover.jpg');
  const { absoluteUrl } = useUrl();

  return (
    <Layout1 overflowHidden lightBlue contentFull noContentPaddings>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:url" content={absoluteUrl} />
        <meta property="og:site_name" content="Sweetheartz" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={pageImage} />
        <meta name="twitter:site" content="@getsweetheartz" />
        <meta name="twitter:creator" content="@getsweetheartz" />
      </Head>
      <div className={styles.root}>
        <HeartAnimation />
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <Container>
              <Row span={12} spanSm={4}>
                <Col span={5} startCol={2} spanMd={6} startColMd={1}>
                  <div className={styles.indexHeroImgMobile}>
                    <Image
                      objectFit="contain"
                      sizes="50vw"
                      loadFromStorage
                      src="/index/hero-mobile.png"
                      priority
                      quality={50}
                    />
                  </div>
                  <h1 className={styles.title}>{intl.formatMessage({ id: 'index.title' })}</h1>
                  <div className={styles.description}>{intl.formatMessage({ id: 'index.description' })}</div>
                  <Mint />
                </Col>
                <Col span={5} startCol={7} className={styles.imageColDesktop}>
                  <div className={styles.heroImg}>
                    <Image
                      objectPosition="center right"
                      objectFit="contain"
                      sizes="50vw"
                      loadFromStorage
                      src="/index/hero.png"
                      priority
                      quality={50}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className={styles.content}>
          <SendSweetheartz />
          <GetSomethingUnique />
          <FAQ />
        </div>
      </div>
    </Layout1>
  );
};

export default Index;
